import React, { useState, forwardRef } from "react";

import apiGhaphql from '../../../services/api'

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import CircularProgress from "../../../common/circular-progress";
import SnackBar from "../../../common/snackbar";

import emailValidator from "../../../common/validators/email";
import confirmEmailValidator from "../../../common/validators/confirmFields";

import sidebar from "../../../assets/vida-connect-logo.svg";

import { title, advanceButton, previousButton, boxButton } from "../style";
import { input } from "../../../common/styles/style";

export default forwardRef((props, ref) => {
  const classesTitle = title();
  const classesInput = input();
  const classesAdvanceButton = advanceButton();
  const classesPreviousButton = previousButton();
  const classesBoxButton = boxButton();

  const [loading, setLoading] = useState(false);

  const type = props.type

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({ error: true, typeError: "" });

  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmEmailError, setConfirmEmailError] = useState({
    error: true,
    typeError: "",
  });

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  function handleAdvance() {
    setLoading(true);

    if (
      !emailValidator(email).error &&
      !confirmEmailValidator(email, confirmEmail).error
    ) {
      setLoading(true);
      const data = {
        provider: {
          query: `
            mutation{
              requestUserProviderPasswordChange(email:"${email}")
            }
          `,
        },
        company: {
          query: `
            mutation{
              requestUserCompanyPasswordChange(email:"${email}")
            }
          `
        }
      }

      // TODO: PAGE NOT FOUND quando data[type] === undefined

      apiGhaphql({
        data: data[type],
      })
        .then((res) => {
          if (res.data.data) {
            setLoading(false);
            props.nextStep();
          } else {
            const messageError = res.data.errors[0].message;
            if (messageError === "E-mail does not match user") {
              setLoading(false);
              setStateSnackBar({
                ...stateSnackBar,
                open: true,
                message: "E-mail não localizado na plataforma.",
              });
            } else {
              setLoading(false);
              setStateSnackBar({
                ...stateSnackBar,
                open: true,
                message: messageError,
              });
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      setEmailError(emailValidator(email));
      setConfirmEmailError(confirmEmailValidator(email, confirmEmail));
      setLoading(false);
    }
  }

  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      {loading && <CircularProgress color="primary" size={80} />}
      <div className="content">
        <Box className={classesTitle.root}>
          <h1>Informe o seu e-mail</h1>
          <h2>Digite o e-mail utilizado para acesso a plataforma.</h2>
        </Box>

        <Grid>
          <Grid className={classesInput.root} item xs={8} md={5}>
            <label htmlFor="email">E-mail</label>
            <Input
              name="email"
              type="email"
              placeholder="Informe seu e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => setEmailError(emailValidator(email))}
              disabled={loading}
              fullWidth
            ></Input>
            <span>{emailError.typeError}</span>
          </Grid>
        </Grid>

        <Grid>
          <Grid className={classesInput.root} item xs={8} md={5}>
            <label htmlFor="confirmEmail">Confirmação do seu e-mail</label>
            <Input
              name="confirmEmail"
              type="email"
              placeholder="Confirme seu e-mail"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              onBlur={() =>
                setConfirmEmailError(confirmEmailValidator(email, confirmEmail))
              }
              disabled={loading}
              fullWidth
            ></Input>
            <span>{confirmEmailError.typeError}</span>
          </Grid>
        </Grid>
      </div>

      <div className="bottom-bar">
        <Box className={classesBoxButton.root}>
          <Button
            onClick={() => handleAdvance()}
            className={classesAdvanceButton.root}
            disabled={loading}
          >
            Avançar
          </Button>
          <Button
            onClick={() => window.history.back()}
            className={classesPreviousButton.root}
            disabled={loading}
          >
            Voltar
          </Button>
        </Box>
      </div>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
        forwardedRef={ref}
      />
    </div>
  );
});
