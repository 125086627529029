import React, { useEffect, useState } from "react";
import apiGhaphql from '../../services/api'

import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";

import sidebar from "../../assets/sidebar.png";
import InputPassword from "../../common/input-password";
import Topic from "../../common/topicSpecial";
import Button from "../../common/button";
import Loading from "../../common/loading";

// import passwordValidator from 'password-validator'

import { inputPassword } from "../../common/styles/style";

import PasswordPattern from './../../common/password-pattern'

const useStyles = makeStyles({
  screen: {
    display: "grid",
    gridTemplateColumns: "minmax(200px, 1.2fr) 4fr",
    gridGap: "4vw",
    height: "100vh",
    minWidth: "1000px",
  },
  sideBar: {
    height: "100%",
    maxHeight: "100vh",
    "& > img": {
      height: "100%",
      width: "100%",
      objectFit: "fill",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  container: {
    marginTop: "3vh",
  },
  notPartner: {
    marginTop: "24px",
  },
  error: {
    marginTop: "2vh",
    color: "#DA1414",
    fontSize: "14px",
  },
  link: {
    textDecoration: "none",
  },
});

const NewSecretary = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const classesInput = inputPassword();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [link, setLink] = useState("");

  const [passwordPaternError, setPasswordPaternError] = useState("")
  const [confirmPasswordPaternError, setConfirmPasswordPaternError] = useState("")

  const [disableButton, setDisableButton] = useState(true)

  const handlePasswordIsValid = (isValid) => {
    if (!isValid) setPasswordPaternError('Crie uma senha com no minimo 8 caracteres, uma letra maiuscula, uma letra minuscula, um numero e um caracter especial: !@#$%^&*_()/-+=.')
    else setPasswordPaternError("")
  }

  // useEffect(() => {
  //   setPasswordPaternError("")
  //   const pattern = new passwordValidator()
  //   pattern
  //     .is()
  //     .min(8)
  //     .is()
  //     .has()
  //     .uppercase()
  //     .is()
  //     .has(/[0-9]/g)
  //     .is()
  //     .has(/[!@#$%^&*_()/\-+=.]/g)

  //   if (!pattern.validate(password) && password !== "") {
  //     setPasswordPaternError('Crie uma senha com no minimo 8 caracteres, uma letra maiuscula, uma letra minuscula, um numero e um caracter especial: !@#$%^&*_()/-+=.')
  //   }
  // }, [password])

  useEffect(() => {
    setConfirmPasswordPaternError("")
    if (confirmPassword !== password && confirmPassword !== '') {
      setConfirmPasswordPaternError('As senhas não são iguais')
    }
  }, [confirmPassword, password])

// const autoLogin = () => {
//   history.push("/administrador");
// }

// useEffect( () => {
//   dispatch(loginActions.checkToken(props.history, "/administrador", autoLogin))
// },[])

const acceptInvitation = async (password) => {
  const url = history.location.pathname;
  const search = history.location.search;
  const [, , secretaryHasProviderId] = url.split("/");
  const [, link] = search.split("?redirect=");
  setLink(link);
  try {
    const res = await apiGhaphql({
      data: {
        query: `
            mutation($input: AcceptInviteSecretaryInput!) {
              acceptInviteForSecretary(input: $input) {
                secretary {
                  id
                }
              }
            }
          `,
        variables: {
          input: {
            secretaryHasProviderId,
            password,
          },
        },
      },
    });
    if (res.data.errors)
      setError(
        "Houve um problema durante a criação da sua senha. Tente novamente, por favor."
      );
    if (res.data.data) setUserCreated(true);
  } catch (error) {
    setError(
      "Houve um problema durante a criação da sua senha. Tente novamente, por favor."
    );
  }
};

const handleSubmit = async () => {
  setLoading(true);
  if (!password || password !== confirmPassword) {
    setError("Confira as senhas digitadas.");
  } else {
    setError("");
    await acceptInvitation(password);
  }
  setLoading(false);
};

useEffect(() => {
  if (password === '' || confirmPassword === '') {
    setDisableButton(true)
    return
  }
  if (!(!Boolean(passwordPaternError) && !Boolean(confirmPasswordPaternError))) {
    setDisableButton(true)
    return
  }
  setDisableButton(false)
}, [password, confirmPassword, passwordPaternError, confirmPasswordPaternError])

return (
  <div className={classes.screen}>
    <Hidden smDown>
      <div className={classes.sideBar}>
        <img src={sidebar} alt="Sidebar Vida Connect" />
      </div>
    </Hidden>
    <div className={classes.root}>
      <Topic
        title="Olá, você foi adicionada(o) como Secretária de um médico"
        subtitle="Crie e confirme uma senha para ter acesso à nossa plataforma."
        fontSize="32px"
        subFontSize="18px"
        marginTop="0px"
        subMarginTop="6px"
      />
      <Grid container className={classes.container}>
        <form>
          {!userCreated && (
            <>
              <Grid>
                <Grid className={classesInput.root}>
                  <label htmlFor="password">Senha</label>
                  <InputPassword
                    name="password"
                    type="password"
                    placeholder="Defina Sua Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    width="360px"
                    tooltip="Crie uma senha com no minimo 8 caracteres, uma letra maiuscula, uma letra minuscula, um numero e um caracter especial"
                  />
                  {/* {Boolean(passwordPaternError) && <span style={{ color: 'red' }}>{passwordPaternError}</span>} */}
                </Grid>
              </Grid>
              <Grid>
                <Grid className={classesInput.root}>
                  <PasswordPattern password={password} isValid={handlePasswordIsValid} />
                </Grid>
              </Grid>
              <Grid>
                <Grid className={classesInput.root}>
                  <label htmlFor="confirmPassword">
                    Confirmação da senha
                    </label>
                  <InputPassword
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirme Sua Senha"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    width="360px"
                    fullWidth
                  />
                  {Boolean(confirmPasswordPaternError) && <span style={{ color: 'red' }}>{confirmPasswordPaternError}</span>}
                </Grid>
              </Grid>
            </>
          )}
          <Grid item className="space-above-item">
            {userCreated ? (
              <a className={classes.link} href={link}>
                <Button
                  width="220px"
                  border="1px solid #DF9000"
                  backgroundColor="#FFFFFF"
                  color="#DF9000"
                  margin="3vh 0 0 0"
                >
                  Fazer Login
                  </Button>
              </a>
            ) : loading ? (
              <Loading width="80px" />
            ) : (
                  <Button
                    width="220px"
                    border="1px solid #DF9000"
                    backgroundColor="#FFFFFF"
                    color="#DF9000"
                    margin="3vh 0 0 0"
                    onClick={handleSubmit}
                    disabled={disableButton}
                  >
                    Criar senha
                  </Button>
                )}
          </Grid>
          <div className={classes.error}>
            <span>{error}</span>
          </div>
        </form>
      </Grid>
    </div>
  </div>
);
};

export default NewSecretary;
