import React from "react";

import PreRegister from "./pages/signup/pre-register";
import PasswordRegister from "./pages/signup/password-register";

import SendEmail from "./pages/reset-password/send-email";
import ChangePassword from "./pages/reset-password/change-password";

import CompleteEmployeeRegister from "./pages/invite/complete-employee-register";
import CompleteAdministratorRegister from "./pages/invite/complete-administrator-register";
import CompleteHealthOperatorEmployeeRegister from "./pages/invite/complete-healthoperator-employee-register";

import ConfirmEmailPatient from "./pages/confirm-email-patient";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import NewSecretary from "./pages/secretary/newSecretary";
import AlreadySecretary from "./pages/secretary/alreadySecretary";

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={PreRegister} />
      <Route path="/confirm-email/:id" component={PasswordRegister} />
      <Route path="/reset-password" component={SendEmail} />
      <Route path="/user-password-reset/:token" component={ChangePassword} />
      <Route
        path="/confirm-email-patient/:token"
        component={ConfirmEmailPatient}
      />
      <Route
        path="/complete-employee-register/:id"
        component={CompleteEmployeeRegister}
      />
      <Route
        path="/create-password-administrator/:id"
        component={CompleteAdministratorRegister}
      />
      <Route
        path="/register-health-operator-employee/:id"
        component={CompleteHealthOperatorEmployeeRegister}
      />
      <Route path="/register-secretary" component={NewSecretary}/>
      <Route path="/invite-secretary" component={AlreadySecretary}/>
    </Switch>
  </BrowserRouter>
);
