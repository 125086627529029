import React from "react";

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import sidebar from "../../../assets/vida-connect-logo.svg";

import {
  titleFinishRegister,
  advanceButton,
  boxButtonChangedPassword,
} from "../style";

export default (props) => {
  const classesFinishRegister = titleFinishRegister();
  const classesButton = advanceButton();
  const classesBoxButton = boxButtonChangedPassword();

  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      <div className="content">
        <Box className={classesFinishRegister.root}>
          <h1>Senha criada com sucesso</h1>
        </Box>
        <Box className={classesBoxButton.root}>
          <a href={`${process.env.REACT_APP_COMPANY}`}>
            <Button className={classesButton.root}>OK</Button>
          </a>
        </Box>
      </div>
    </div>
  );
};
