import React from "react";

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

import sidebar from "../../../assets/vida-connect-logo.svg";

import { titleConfirmEmail } from "../style";

export default () => {
  const classesTtitleConfirmEmail = titleConfirmEmail();

  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      <div className="content">
        <Box className={classesTtitleConfirmEmail.root}>
          <h1>Confirme seu Email</h1>
          <h2>
            Enviamos para o e-mail informado o link para redefinir sua senha.
          </h2>
        </Box>
      </div>
    </div>
  );
};
