import React from "react";

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";

import empty from "../../../common/validators/empty";

import sidebar from "../../../assets/vida-connect-logo.svg";

import { checkbox } from "../../../common/styles/style";
import { title, advanceButton, boxButton } from "../style";

export default (props) => {
  const classesCheckbox = checkbox();
  const classesTitle = title();
  const classesAdvanceButton = advanceButton();
  const classesBoxButton = boxButton();

  const type = props.type;
  const setType = props.setType;

  function handleAdvance() {
    if (!empty(type).error) {
      props.nextStep();
    }
  }

  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      <div className="content">
        <Box className={classesTitle.root}>
          <h1>Tipo de cadastro</h1>
          <h2>Como deseja fazer seu cadastro no Vida Connect?</h2>
        </Box>

        <FormControl className={classesCheckbox.root}>
          <FormLabel component="legend"></FormLabel>
          <RadioGroup
            aria-label="type-account"
            value={type}
            onChange={(e) => setType(e.target.value)}
            name="type-account"
          >
            <FormControlLabel
              value="PROVIDER"
              control={<Radio />}
              label="Pessoa Física (PF)"
            />
            <FormControlLabel
              value="COMPANY"
              control={<Radio />}
              label="Pessoa Jurídica (PJ)"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="bottom-bar">
        <Box className={classesBoxButton.root}>
          <Button
            onClick={() => handleAdvance()}
            className={classesAdvanceButton.root}
          >
            Avançar
          </Button>
        </Box>
      </div>
    </div>
  );
};
