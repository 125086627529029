import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import LoadingGIF from '../../assets/loading.gif';

const useStyles = makeStyles({
    loading: {
        width: "100%",
        textAlign: "center",
        margin: "0 auto",
        "& > img": {
            width: props => props.width ? props.width : "200px",
        }
    }
})

const Loading = (props) => {
    const classes = useStyles(props);
    return (
        <div className={classes.loading}>
            <img src={LoadingGIF} alt="Loading"/>
        </div>
    )
};

export default Loading;