import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'

export default function Approval() {
  return (
    <Fragment>
      <Typography variant="h1">Convite aceito!</Typography>
      <Typography variant="h2">Você já pode acessar a plataforma e disponibilizar uma agenda para esta instituição.</Typography>
    </Fragment>
  )
}
