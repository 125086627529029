import passwordValidator from "password-validator";
import empty from "./empty";

export default (password) => {
  if (empty(password).error) {
    return { error: true, typeError: "Esse Campo é obrigatório!" };
  }
  const validatePass = new passwordValidator();

  validatePass
    .is()
    .min(8) // Valida a quantidade de caracteres minimos
    .is()
    .has()
    .uppercase() // Valida se tem pelo menos uma letra maiuscula
    .is()
    .has(/[0-9]/g) // Valida se tem pelo menos um numero
    .is()
    .has(/[!@#$%^&*_()-+=./]/g); // Valida se tem pelo menos um caracter especial

  if (!validatePass.validate(password)) {
    return {
      error: true,
      typeError:
        "A senha deve conter no mínimo 8 caracteres, uma letra maiuscula, uma letra minuscula, um numero e um caracter especial",
    };
  } else {
    return {
      error: false,
      typeError: "",
    };
  }
};
