import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const PasswordPattern = (props) => {
  const { password, isValid } = props

  const validateLength = (pass) => pass.length >= 8
  const validateUpperCase = (pass) => new RegExp(/[A-Z]/g).test(pass)
  const validateLowerCase = (pass) => new RegExp(/[a-z]/g).test(pass)
  const validateNumbers = (pass) => new RegExp(/\d/g).test(pass)
  const validateSpecialCharacter = (pass) => new RegExp(/[!@#$%^&*_()/\-+=.]/g).test(pass)

  useEffect(() => {
    const validate = () => Promise.all(
      [
        validateLength(password),
        validateUpperCase(password),
        validateLowerCase(password),
        validateNumbers(password),
        validateSpecialCharacter(password),
      ]
    )

    validate().then(r => isValid(!r.some(i => !i)))
  }, [password, isValid])

  return (
    <div>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box>
          {validateLength(password) ? <CheckIcon fontSize="small" style={{ color: 'green' }} /> : <ClearIcon fontSize="small" style={{ color: 'red' }} />}
        </Box>
        <Box>
          <span style={{ color: validateLength(password) ? 'green' : 'red' }}>Minimo 8 caracteres</span>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box>
          {validateUpperCase(password) ? <CheckIcon fontSize="small" style={{ color: 'green' }} /> : <ClearIcon fontSize="small" style={{ color: 'red' }} />}
        </Box>
        <Box>
          <span style={{ color: validateUpperCase(password) ? 'green' : 'red' }}>Uma letra maiúscula</span>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box>
          {validateLowerCase(password) ? <CheckIcon fontSize="small" style={{ color: 'green' }} /> : <ClearIcon fontSize="small" style={{ color: 'red' }} />}
        </Box>
        <Box>
          <span style={{ color: validateLowerCase(password) ? 'green' : 'red' }}>Uma letra minúscula</span>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box>
          {validateNumbers(password) ? <CheckIcon fontSize="small" style={{ color: 'green' }} /> : <ClearIcon fontSize="small" style={{ color: 'red' }} />}
        </Box>
        <Box>
          <span style={{ color: validateNumbers(password) ? 'green' : 'red' }}>Pelo menos um número</span>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box>
          {validateSpecialCharacter(password) ? <CheckIcon fontSize="small" style={{ color: 'green' }} /> : <ClearIcon fontSize="small" style={{ color: 'red' }} />}
        </Box>
        <Box>
          <span style={{ color: validateSpecialCharacter(password) ? 'green' : 'red' }}>Caractere especial: {'!@#$%^&*_()/-+=.'}</span>
        </Box>
      </Box>
    </div>
  )
}

export default PasswordPattern
