import React from "react";

import Box from "@material-ui/core/Box";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { boxCircularProgress } from "../styles/style";

export default (props) => {
  const classesBoxCircularProgress = boxCircularProgress();

  const themeCircularProgress = createMuiTheme({
    palette: {
      primary: { main: "#DF9000" },
    },
  });
  return (
    <Box className={classesBoxCircularProgress.root}>
      <ThemeProvider theme={themeCircularProgress}>
        <CircularProgress color={props.color} size={props.size} />
      </ThemeProvider>
    </Box>
  );
};
