import React, { useState } from "react";

import apiGraphql from '../../../services/api'

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import CircularProgress from "../../../common/circular-progress";

import SnackBar from "../../../common/snackbar";

import maskCpf from "../../../common/mask/cpf";
import maskPhone from "../../../common/mask/telephone";

import cpfValidator from "../../../common/validators/cpf";
import empty from "../../../common/validators/empty";
import phoneValidator from "../../../common/validators/telephone";
import emailValidator from "../../../common/validators/email";

import sidebar from "../../../assets/vida-connect-logo.svg";

import { title, advanceButton, previousButton, boxButton } from "../style";
import { input } from "../../../common/styles/style";

export default (props) => {
  const classesTitle = title();
  const classesInput = input();
  const classesAdvanceButton = advanceButton();
  const classesPreviousButton = previousButton();
  const classesBoxButton = boxButton();

  const [loading, setLoading] = useState(false);

  let { type } = props;
  const { cpf, setCpf } = props;
  const { email, setEmail } = props;
  const { name, setName } = props;
  const { phone, setPhone } = props;

  const [cpfError, setCpfError] = useState({ error: true, typeError: "" });
  const [nameError, setNameError] = useState({ error: true, typeError: "" });
  const [emailError, setEmailError] = useState({ error: true, typeError: "" });
  const [phoneError, setPhoneError] = useState({
    error: true,
    typeError: "",
  });

  const [stateSnackBar, setStateSnackBar] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  function handleAdvance() {
    setLoading(true);
    setCpfError(cpfValidator(cpf));
    setNameError(empty(name));
    setEmailError(emailValidator(email));
    setPhoneError(phoneValidator(phone));

    if (
      !(
        cpfError.error ||
        nameError.error ||
        emailError.error ||
        phoneError.error
      ) 
    ) {
      apiGraphql({
        data: {
          query: `
            mutation createUser($input: CreateUserInput!){
              createUser(input:$input){
                userCreated
              }
            }
          `,
          variables: {
            input: { email, name, cpf, phone, type },
          },
        },
      })
        .then((res) => {
          if (res.data.errors) {
            setLoading(false);
            const error = res.data.errors[0].message;
            if (error === "User already exists") {
              return setStateSnackBar({
                ...stateSnackBar,
                message: "Usuário Já Cadastrado",
                open: true,
              });
            } else if (
              error === "Validation error: Validation isEmail on email failed"
            ) {
              return setStateSnackBar({
                ...stateSnackBar,
                message: "O email digitado está incorreto",
                open: true,
              });
            } else if (error === "Data too long for column 'name' at row 1") {
              return setStateSnackBar({
                ...stateSnackBar,
                message: "O Nome digitado é muito grande, por favor verifique!",
                open: true,
              });
            } else {
              return setStateSnackBar({
                ...stateSnackBar,
                message: res.data.errors[0].message,
                open: true,
              });
            }
          } else {
            props.nextStep();
          }
        })
        .catch((err) => {
          setStateSnackBar({
            ...stateSnackBar,
            message: err,
            open: true,
          });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }

  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      {loading && <CircularProgress color="primary" size={80} />}
      <div className="content">
        <Box className={classesTitle.root}>
          <h1>Dados Pessoais</h1>
          <h2>Precisamos de algumas informações suas para começar.</h2>
        </Box>

        <Grid>
          <Grid className={classesInput.root} item xs={6} md={3}>
            <label htmlFor="cpf">CPF</label>
            <Input
              name="cpf"
              placeholder="Insira seu CPF"
              value={cpf}
              onChange={(e) => setCpf(maskCpf(e.target.value))}
              onBlur={() => setCpfError(cpfValidator(cpf))}
              disabled={loading}
              fullWidth
            ></Input>
            <span>{cpfError.typeError}</span>
          </Grid>
        </Grid>
        <Grid>
          <Grid className={classesInput.root} item xs={8} md={5}>
            <label htmlFor="name">Nome</label>
            <Input
              name="name"
              placeholder="Nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={() => setNameError(empty(name))}
              disabled={loading}
              fullWidth
            ></Input>
            <span>{nameError.typeError}</span>
          </Grid>
        </Grid>
        <Grid>
          <Grid className={classesInput.root} item xs={8} md={5}>
            <label htmlFor="email">Email</label>
            <Input
              name="email"
              type="email"
              placeholder="Informe seu email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => setEmailError(emailValidator(email))}
              disabled={loading}
              fullWidth
            ></Input>
            <span>{emailError.typeError}</span>
          </Grid>
        </Grid>
        <Grid>
          <Grid className={classesInput.root} item xs={6} md={3}>
            <label htmlFor="phone">Telefone</label>
            <Input
              name="phone"
              placeholder="Informe seu telefone"
              value={maskPhone(phone)}
              onChange={(e) => setPhone(maskPhone(e.target.value))}
              onBlur={() => setPhoneError(phoneValidator(phone))}
              disabled={loading}
              fullWidth
            ></Input>
            <span>{phoneError.typeError}</span>
          </Grid>
        </Grid>
      </div>

      <div className="bottom-bar">
        <Box className={classesBoxButton.root}>
          <Button
            onClick={() => handleAdvance()}
            className={classesAdvanceButton.root}
            disabled={loading}
          >
            Avançar
          </Button>
          {type !== "COMPANY" && 
            <Button
              onClick={() => props.previousStep()}
              className={classesPreviousButton.root}
              disabled={loading}
            >
              Voltar
            </Button>
          }
        </Box>
      </div>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
};
