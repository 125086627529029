import { makeStyles } from "@material-ui/core/styles";

const title = makeStyles({
  root: {
    marginTop: "89px",
    "& h1": {
      margin: "0 0 11px 0",
      fontWeight: "bold",
      color: "#272833",
      fontSize: "1.71rem",
    },
    "& h2": {
      margin: "0 0 25px 0",
      fontSize: "1.28rem",
      fontWeight: "normal",
      color: "#393A4A",
    },
  },
});

const advanceButton = makeStyles({
  root: {
    padding: 0,
    height: "40px",
    width: "140px",
    border: "1px solid #DF9000;",
    borderRadius: "4px",
    fontSize: "1.14rem",
    fontWeight: 600,
    color: "#DF9000",
    backgroundColor: "#FFF",
    textTransform: "none",
  },
});

const previousButton = makeStyles({
  root: {
    padding: 0,
    height: "40px",
    width: "140px",
    borderRadius: "4px",
    fontSize: "1.14rem",
    fontWeight: 600,
    border: "1px solid #CDCED9",
    color: "#6B6C7E",
    backgroundColor: "#FFF",
    textTransform: "none",
  },
});

const boxButton = makeStyles({
  root: {
    height: "100%",
    backgroundColor: "#f5f5f5",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    padding: "0 7.8vw",
    "& a": {
      textDecoration: "none",
    },
  },
});

const boxButtonChangedPassword = makeStyles({
  root: {
    "& a": {
      textDecoration: "none",
    },
  },
});

const titleFinishRegister = makeStyles({
  root: {
    marginTop: "32.63vh",
    "& h1": {
      fontSize: "2.28rem",
      fontWeight: "bold",
      color: "#272833",
    },
  },
});

export {
  title,
  advanceButton,
  previousButton,
  boxButton,
  boxButtonChangedPassword,
  titleFinishRegister,
};
