import { makeStyles } from "@material-ui/core/styles";

const input = makeStyles({
  root: {
    marginTop: "16px !important",
    minHeight: "40px",

    "& label": {
      fontWeight: 600,
      color: "#272833",
    },
    "& input": {
      padding: 0,
      height: "40px",
      border: "1px solid #e7e7ed !important",
      textIndent: "0.4rem",
      borderRadius: "4px ",
    },
    "& span": {
      fontSize: "0.8rem",
      color: "#da1414",
    },
  },
});

const inputPassword = makeStyles({
  root: {
    marginTop: "16px !important",
    "& label": {
      fontWeight: 600,
      color: "#272833",
    },
  },
});

const checkbox = makeStyles({
  root: {
    "& label": {
      border: "1px solid #E7E7ED",
      borderRadius: "4px",
      height: "40px",
      width: "80%",
      maxWidth: "300px",
      margin: "0 0 10px 0",
    },
    "& span": {
      fontSize: "1.14rem",
      fontWeight: "normal",
    },
  },
});

const boxCircularProgress = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    height: "100vh",
    width: "100vw",
    zIndex: 10,
  },
});

export { input, checkbox, boxCircularProgress, inputPassword };
