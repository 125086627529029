import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: props => props.textAlign ? props.textAlign : "flex-start",
    marginTop: props => props.marginTop ? props.marginTop : '30px',
  },
  title: {
    fontSize: props => props.fontSize ? props.fontSize : "20px",
    fontWeight: 'bold',
    color: props => props.color ? props.color : '#272833'
  },
  subtitle: {
    fontSize: props => props.subFontSize ? props.subFontSize : "16px",
    fontWeight: 'normal',
    color: props => props.subColor ? props.subColor : '#6B6C7E',
    marginTop: props => props.subMarginTop ? props.subMarginTop : "0",
  }
})

export default (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.text}>
      <div className={classes.title}>
        <span >{props.title}</span>
      </div>
      <div className={classes.subtitle}>
        {props.subtitle ? 
            <span >
              {props.subtitle}
            </span>
            :
            null
        }
        {props.warning ? <div><span className={'error-input'}>{props.warning}</span></div> : null } 
      </div>
    </div>
  )
};
