import React, { useState } from "react";

import BePartner from "./bePartner";
import TypeAccount from "./typeAccount";
import PersonalData from "./personalData";
import ConfirmEmail from "./confirmEmail";

export default () => {
  const [step, setStep] = useState(0);
  const [type, setType] = useState("");
  const [cpf, setCpf] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  switch (step) {
    
    case 1:
      return (
        <TypeAccount
          nextStep={() => setStep(step + 1)}
          type={type}
          setType={setType}
        />
      );

    case 2:
      return (
        <PersonalData
          previousStep={() => setStep(step - 1)}
          nextStep={() => setStep(step + 1)}
          type={type}
          cpf={cpf}
          setCpf={setCpf}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
        />
      );

    case 3:
      return <ConfirmEmail />;

    default:
      return <BePartner nextStep={() => setStep(step + 1)} />;
  }
};
