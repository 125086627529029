import React from "react";

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import sidebar from "../../../assets/vida-connect-logo.svg";

import { titleFinishRegister, advanceButton, boxButton } from "../style";

export default (props) => {
  const classesTitleFinishRegister = titleFinishRegister();
  const classesAdvanceButton = advanceButton();
  const classesBoxButton = boxButton();
  const url = props.url;

  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>

      <div className="content">
        <Box className={classesTitleFinishRegister.root}>
          <h1>
            Estamos quase lá, faltam poucos <br />
            passos para você fazer parte do
            <br />
            <strong>Vida Connect</strong>
          </h1>
        </Box>
      </div>
      <div className="bottom-bar">
        <Box className={classesBoxButton.root}>
          <a href={url}>
            <Button className={classesAdvanceButton.root}>Continuar</Button>
          </a>
        </Box>
      </div>
    </div>
  );
};
