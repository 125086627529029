import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'

export default function Error() {
  return (
    <Fragment>
      <Typography variant="h1">Desculpe, houve um erro ao aceitar o convite</Typography>
      <Typography variant="h2">Por favor, tente novamente mais tarde</Typography>
    </Fragment>
  )
}
