import React, { useEffect, useState, Fragment } from 'react'
import apiGhaphql from '../../services/api'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import sidebar from "./../../assets/vida-connect-logo.svg";
import CircularProgress from "../../common/circular-progress"
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    marginTop: "40.7vh",
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: "2.28rem",
      fontWeight: "bold",
      color: "#272833",
    },
    "& h2": {
      margin: "6px 0 32px 0",
      padding: 0,
      fontSize: "2rem",
      fontWeight: "normal",
      color: "##393A4A",
    },
  },
});

export default function ConfirmEmailPatient(props) {
  const classes = useStyles()
  const [success, setSuccess] = useState(null)
  const token = props.match.params.token

  useEffect(() => {
    apiGhaphql({
      data: {
        query: `
          mutation {
            validateEmailPatient(userId: "${token}") 
          }
        `
      }
    }).then(res => res.data.errors ? setSuccess(false) : setSuccess(true))
      .catch(() => setSuccess(false))
  }, [token])

  const ok = () => (
    <Fragment>
      <Typography variant="h1">E-mail confirmado com sucesso!</Typography>
      <Typography variant="h2">Você já pode acessar o app e fazer login com seus dados.</Typography>
    </Fragment>
  )

  const error = () => (
    <Fragment>
      <Typography variant="h1">Desculpe, houve um erro ao confirmar seu e-mail</Typography>
      <Typography variant="h2">Por favor, tente novamente mais tarde</Typography>
    </Fragment>
  )

  const layout = () => {
    switch (success) {
      case null:
        return <CircularProgress color="primary" size={80} />

      case false:
        return error()

      case true:
        return ok()

      default:
        return error()
    }
  }

  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      <div className="content">
        <Box className={classes.root}>
          {layout()}
        </Box>
      </div>
    </div>
  )
}
