import React, { useState, useEffect } from "react";
import apiGhaphql from '../../services/api'

import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from '@material-ui/core/styles';

import sidebar from "../../assets/sidebar.png";
import Topic from "../../common/topicSpecial";
import Button from "../../common/button";

const useStyles = makeStyles({
  screen: {
    display: 'grid',
    gridTemplateColumns: "minmax(200px, 1.2fr) 4fr",
    gridGap: '4vw',
    height: '100vh',
    minWidth: '1000px',
  },
  sideBar: {
    height: '100%',
    maxHeight: '100vh',
    "& > img": {
      height: '100%',
      width: '100%',
      objectFit: 'fill'
    }
  },
  root: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    marginTop:'3vh',
  },
  notPartner: {
    marginTop: '24px'
  },
  error: {
    marginTop: '2vh',
    color:"#DA1414",
    fontSize: '14px',
  },
  link: {
    textDecoration: "none",
  }
});

const NewSecretary = (props) => {
  const classes = useStyles ();
  const history = useHistory();

  const [error, setError] = useState("");
  const [link, setLink] = useState("");

  useEffect( () => {
    acceptInvitation();
    //eslint-disable-next-line
  },[])

  const acceptInvitation = async (password) => {
    const url = history.location.pathname;
    const search = history.location.search;
    const [,,secretaryHasProviderId] = url.split('/');
    const [,link] = search.split("?redirect=");
    setLink(link);
    try {
      const res = await apiGhaphql({
        data: {
          query:
          `
            mutation($input: AcceptInviteSecretaryInput!) {
              acceptInviteForSecretary(input: $input) {
                secretary {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              secretaryHasProviderId,
              password
            }
          }
        }
      });
      console.log(res.data.errors)
      if(res.data.errors) {
        
        setError("Houve um problema durante a sua adição. Tente recarregar a página, por favor.");
      }
    }catch(error) {
      setError("Houve um problema durante a sua adição. Tente recarregar a página, por favor.");
    }
  }

  return (
    <div className={classes.screen}>
      <Hidden smDown>
        <div className={classes.sideBar}>
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      {Boolean(error)
      ? <div className={classes.root}>
          <Topic
            title="Houve um problema durante a sua adição. Tente recarregar a página, por favor."
            subtitle="Caso persista, certifique-se de que este link ja não foi usado antes."
            fontSize="32px"
            subFontSize="18px"
            marginTop="0px"
            subMarginTop="6px"
            color="#DA1414"
          />
        </div>
      : <div className={classes.root}>
          <Topic
            title="Olá, você foi adicionada(o) como Secretária de um médico"
            subtitle="Faça o login na plataforma para aceitar o convite."
            fontSize="32px"
            subFontSize="18px"
            marginTop="0px"
            subMarginTop="6px"
          />
          <Grid container className={classes.container}>
              <Grid item className="space-above-item">
                <a className={classes.link} href={link}>
                  <Button
                  width="220px"
                  border="1px solid #DF9000"
                  backgroundColor="#FFFFFF"
                  color="#DF9000"
                  margin="3vh 0 0 0"
                  >
                      Fazer Login
                  </Button>
                </a>
                <span className={classes.error}>{error}</span>
              </Grid>
          </Grid>
        </div>
      }
    </div>
  );
};

export default NewSecretary;
