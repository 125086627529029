import React, { useState, useEffect } from "react";

import jwt from "jsonwebtoken";

import ResetPassword from "./resetPassword";
import ChangedSuccess from "./changedSuccess";

export default (props, ref) => {
  const token = props.match.params.token;
  const [userType, setUserType] = useState("");

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (jwt.decode(token)) {
      const { payload } = jwt.decode(token);
      setUserType(payload.type);
    }
  }, [token]);

  const nextStep = () => {
    setStep(step + 1);
  };

  switch (step) {
    case 0:
      return <ResetPassword token={token} nextStep={nextStep} />;
    case 1:
      return <ChangedSuccess userType={userType} />;
    default:
      return <ResetPassword />;
  }
};
