import React from "react";

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

import sidebar from "../../../assets/vida-connect-logo.svg";

import { titleConfirmEmail } from "../style";

export default () => {
  const classesTtitleConfirmEmail = titleConfirmEmail();

  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      <div className="content">
        <Box className={classesTtitleConfirmEmail.root}>
          <h1>Confirme seu Email</h1>
          <h2>Verifique a sua caixa de entrada.</h2>
          <h3>Caro parceiro, enviaremos um link para validação de sua conta. Se possível, valide o link agora e depois, realize o seu primeiro acesso. Caso opte por fazer isso mais tarde, guarde bem esse email enviado. Somente através dele, será possível avançar em seu cadastro.</h3>
        </Box>
      </div>
    </div>
  );
};
