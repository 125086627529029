import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerUserPropertiesToAnalytics from './config/register-user-properties-to-analytics'

// Help Desk Tawk.to Script
if (Boolean(process.env.REACT_APP_HELP_DESK)) {
  var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = process.env.REACT_APP_HELP_DESK;
  // s1.charset = 'UTF-8';
  s1.setAttribute('crossorigin', '*');
  s0.parentNode.insertBefore(s1, s0);
}

if (
  process.env.REACT_APP_ENVIRONMENT &&
  process.env.REACT_APP_ENVIRONMENT === 'production'
) {
  registerUserPropertiesToAnalytics()
}

ReactDOM.render(<App />, document.getElementById("root"));
