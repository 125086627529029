import React, { useState } from "react";

import RegisterPassword from "./registerPassword";
import Approval from "./approval";

export default (props) => {
  const userId = props.match.params.id;
  const [step, setStep] = useState(0);

  switch (step) {
    case 0:
      return <RegisterPassword setStep={setStep} userId={userId} />;
    case 1:
      return <Approval />;
    default:
      return <></>;
  }
};
