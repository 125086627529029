import React from "react";

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import sidebar from "../../../assets/vida-connect-logo.svg";

import { titleBePartner, buttonBePartner } from "../style";

export default (props) => {
  const classesTitleBePartner = titleBePartner();
  const classesButtonBePartner = buttonBePartner();
  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      <div className="content">
        <Box className={classesTitleBePartner.root}>
          <h1>Seja um parceiro do Vida Connect.</h1>
        </Box>

        <Box className={classesButtonBePartner.root}>
          <Button onClick={() => props.nextStep()}>
            Quero ser um parceiro
          </Button>
        </Box>
      </div>
    </div>
  );
};
