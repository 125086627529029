import React, { useState } from "react";

import apiGhaphql from '../../../services/api'

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputPassword from "../../../common/input-password";
import Button from "@material-ui/core/Button";
import CircularProgress from "../../../common/circular-progress";
import SnackBar from "../../../common/snackbar";

import sidebar from "../../../assets/vida-connect-logo.svg";
import passwordValidator from "../../../common/validators/password";
import confirmPasswordValidator from "../../../common/validators/confirmFields";

import PasswordPattern from './../../../common/password-pattern'

import { title, advanceButton } from "../style";
import { inputPassword } from "../../../common/styles/style";

export default (props, ref) => {
  const classesTitle = title();
  const classesInput = inputPassword();
  const classesAdvanceButton = advanceButton();

  const { token } = props;

  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState({
    error: false,
    typeError: "",
  });
  const [passwordPattern, setPasswordPattern] = useState(false)

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState({
    error: false,
    typeError: "",
  });

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  function handleAdvance() {
    setLoading(true);

    if (
      !(
        passwordValidator(password).error ||
        confirmPasswordValidator(password, confirmPassword).error
      )
    ) {
      apiGhaphql({
        data: {
          query: `
                mutation changeUserPassword($input:ChangePasswordInput!){
                    changeUserPassword(input:$input)
                }
              `,
          variables: { input: { password, token } },
        },
      })
        .then((res) => {
          if (res.data.errors) {
            const messageError = res.data.errors[0].message;
            if (messageError === "Token expired") {
              setLoading(false);
              setStateSnackBar({
                ...stateSnackBar,
                open: true,
                message:
                  "Desculpe, o tempo para troca de senha expirou. Por favor envie novamente o e-mail para realizar a troca de sua senha!",
              });
            } else {
              setLoading(false);
              setStateSnackBar({
                ...stateSnackBar,
                open: true,
                message: messageError,
              });
            }
          } else {
            setLoading(false);
            props.nextStep();
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setStateSnackBar({
            ...stateSnackBar,
            open: true,
            message:
              "Desculpe houve um erro ao tentar se conectar ao servidor, por favor tente mais tarde.",
          });
        });
    } else {
      setLoading(false);
      setPasswordError(passwordValidator(password));

      setConfirmPasswordError(
        confirmPasswordValidator(password, confirmPassword)
      );
    }
  }

  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      {loading && <CircularProgress color="primary" size={80} />}

      <div className="content">
        <Box className={classesTitle.root}>
          <h1>Redefinir sua senha</h1>
          <h2>Defina uma nova senha de acesso.</h2>
        </Box>

        <Grid>
          <Grid className={classesInput.root} item xs={8} md={5}>
            <label htmlFor="password">Nova senha</label>

            <InputPassword
              name="password"
              type="password"
              placeholder="Defina Sua Nova Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={() => setPasswordError(passwordValidator(password))}
              fullWidth
              error={passwordError.typeError}
              tooltip="Crie uma senha com  no minimo 8 caracteres, uma letra maiuscula, uma letra minuscula, um numero e um caracter especial"
            />
          </Grid>
          <Grid>
            <PasswordPattern
              password={password}
              isValid={setPasswordPattern}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid className={classesInput.root} item xs={8} md={5}>
            <label htmlFor="confirmPassword">Confirmação da nova senha</label>
            <InputPassword
              name="confirmPassword"
              type="password"
              placeholder="Confirme Sua Nova Senha"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={() =>
                setConfirmPasswordError(
                  confirmPasswordValidator(password, confirmPassword)
                )
              }
              fullWidth
              error={confirmPasswordError.typeError}
            />
            
            <Box style={{marginTop: '40px'}}>
              <Button
                onClick={() => handleAdvance()}
                className={classesAdvanceButton.root}
                disabled={!passwordPattern || loading}
              >
                Avançar
              </Button>
            </Box>

          </Grid>
        </Grid>
      </div>

      {/* <div className="bottom-bar">
        <Box className={classesBoxButton.root}>
          <Button
            onClick={() => handleAdvance()}
            className={classesAdvanceButton.root}
            disabled={!passwordPattern || loading}
          >
            Avançar
          </Button>
        </Box>
      </div> */}
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
};
