import React, { useState, useEffect } from "react";
import apiGhaphql from '../../../services/api'
import Approval from "./approval";
import Error from "./error";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "../../../common/circular-progress";
import sidebar from "../../../assets/vida-connect-logo.svg";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    marginTop: "40.7vh",
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: "2.28rem",
      fontWeight: "bold",
      color: "#272833",
    },
    "& h2": {
      margin: "6px 0 32px 0",
      padding: 0,
      fontSize: "2rem",
      fontWeight: "normal",
      color: "##393A4A",
    },
  },
});

export default function CompleteEmployeeRegister(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [ok, setOk] = useState(false);

  const employeeId = props.match.params.id;

  useEffect(() => {
    const request = async () => {
      setLoading(true);
      try {
        const api = await apiGhaphql({
          data: {
            query: `
              mutation($input: CompleteEmployeeRegisterInput!) {
                completeEmployeeRegister(input: $input) {
                  status
                }
              }
            `,
            variables: { input: { employeeId } },
          }
        });
        const { data } = api;
        if (data.errors) {
          setOk(false);
          setLoading(false);
          return;
        }
        setOk(true);
        setLoading(false);
      } catch (error) {
        setOk(false);
        setLoading(false);
      }
    };
    request();
  }, [employeeId]);

  const app = () => (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      <div className="content">
        <Box className={classes.root}>{ok ? <Approval /> : <Error />}</Box>
      </div>
    </div>
  );

  return (
    <div>
      {loading ? <CircularProgress color="primary" size={80} /> : app()}
    </div>
  );
}
