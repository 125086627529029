import React, { useState, useEffect } from "react";

import apiGraphql from '../../../services/api'

import { useHistory } from "react-router-dom";

import DefinePassword from "./definePassword";
import FinishRegister from "./finishRegister";
import PageError from "./pageError";

export default (props) => {
  const [step, setStep] = useState(0);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const id = props.match.params.id;
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    apiGraphql({
      data: {
        query: `
          query checkPreRegister($userId: String!){
            checkPreRegister(userId:$userId){
              type
            }
          }
        `,
        variables: {
          userId: id,
        },
      },
    })
      .then((res) => {
        const type = res.data.data.checkPreRegister.type;
        if (!(type === "COMPANY" || type === "PROVIDER")) setStep(2);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setStep(2);
      });
  }, [history, id]);

  switch (step) {
    case 1:
      return <FinishRegister url={url} />;

    case 2:
      return <PageError />;

    default:
      return (
        <React.Fragment>
          <DefinePassword
            nextStep={() => setStep(step + 1)}
            loading={loading}
            setLoading={setLoading}
            id={id}
            setUrl={setUrl}
          />
        </React.Fragment>
      );
  }
};
