import React from "react";

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

import { title } from "../style";

import sidebar from "../../../assets/vida-connect-logo.svg";
export default () => {
  const classesTitle = title();
  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>

      <div className="content">
        <Box className={classesTitle.root}>
          <h1>
            Deculpe, houve um erro ao tentar confirmar seu e-mail ou o usuario
            já existe cadastrado.
          </h1>
          <h1>Tente novamente mais tarde.</h1>
        </Box>
      </div>
    </div>
  );
};
