import React, { useState } from "react";
import apiGraphql from '../../../services/api'

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputPassword from "../../../common/input-password";
import Button from "@material-ui/core/Button";
import CircularProgress from "../../../common/circular-progress";

import sidebar from "../../../assets/vida-connect-logo.svg";
import passwordValidator from "../../../common/validators/password";
import confirmPasswordValidator from "../../../common/validators/confirmFields";

import { title, advanceButton, boxButton } from "../style";
import { inputPassword } from "../../../common/styles/style";
 
import PasswordPattern from './../../../common/password-pattern'

export default (props) => {
  const classesTitle = title();
  const classesInput = inputPassword();
  const classesAdvanceButton = advanceButton();
  const classesBoxButton = boxButton();

  const { loading, setLoading } = props;

  const id = props.id;
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState({
    error: false,
    typeError: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState({
    error: false,
    typeError: "",
  });

  const [passwordPattern, setPasswordPattern] = useState(false)

  function handleAdvance() {
    setLoading(true);

    if (
      !(
        passwordValidator(password).error ||
        confirmPasswordValidator(password, confirmPassword).error
      )
    ) {
      apiGraphql({
        data: {
          query: `
            mutation completePreRegister($input: CompletePreRegisterInput!){
              completePreRegister(input:$input){ 
                urlToLogin
              }
            }
          `,
          variables: {
            input: { id, password },
          },
        },
      })
        .then((res) => {
          props.setUrl(res.data.data.completePreRegister.urlToLogin);
          props.nextStep();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setLoading(false);
      setPasswordError(passwordValidator(password));

      setConfirmPasswordError(
        confirmPasswordValidator(password, confirmPassword)
      );
    }
  }

  return (
    <div className="screen">
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      {loading && <CircularProgress color="primary" size={80} />}

      <div className="content">
        <Box className={classesTitle.root}>
          <h1>Defina a sua senha</h1>
          <h2>
            O seu email foi confirmado com sucesso agora defina a sua senha.
          </h2>
        </Box>

        <Grid>
          <Grid className={classesInput.root} item xs={8} md={5}>
            <label htmlFor="password">Senha</label>

            <InputPassword
              name="password"
              placeholder="Defina Sua Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={() => setPasswordError(passwordValidator(password))}
              error={passwordError.typeError}
              fullWidth
              tooltip="Crie uma senha com  no minimo 8 caracteres, uma letra maiúscula, uma letra minúscula, um numero e um caracter especial"
            />
          </Grid>
          <Grid>
            <PasswordPattern
              password={password}
              isValid={setPasswordPattern}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid className={classesInput.root} item xs={8} md={5}>
            <label htmlFor="confirmPassword">Confirmação da Senha</label>

            <InputPassword
              name="confirmPassword"
              type="password"
              placeholder="Confirme Sua Senha"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={() =>
                setConfirmPasswordError(
                  confirmPasswordValidator(password, confirmPassword)
                )
              }
              fullWidth
              error={confirmPasswordError.typeError}
            />
          </Grid>
        </Grid>
      </div>

      <div className="bottom-bar">
        <Box className={classesBoxButton.root}>
          <Button
            onClick={() => handleAdvance()}
            className={classesAdvanceButton.root}
            disabled={!passwordPattern || loading}
          >
            Avançar
          </Button>
        </Box>
      </div>
    </div>
  );
};
