import React, { useState } from "react";

import SendEmail from "./sendEmail";
import VerifyEmail from "./verifyEmail";

export default (props) => {
  // Função que pega querystring da rota
  // Ex: https://google.com?variavel=123
  const query = new URLSearchParams(props.location.search);

  const type = query.get('type')

  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep(step + 1);
  };
  switch (step) {
    case 0:
      return <SendEmail nextStep={nextStep} type={type} />;

    case 1:
      return <VerifyEmail />;
    default:
      return <SendEmail nextStep={nextStep} type={type} />;
  }
};
